/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import fetch from '@api/fetchers';
import { fetchProxyUserUID, abVariations } from '@utils/abHelper';
import { HOST_TYPE } from '@utils/api-helper/host';
import { experimentsStore } from '@stores/experiments';

export const fetchABExperimentData = async (
  experiment,
  token = null,
  proxyUID
) => {
  return {};
  // if (!process.env.AB_SERVER_URL) return null;
  // const data = await fetch('activate', {
  //   requestType: 'post',
  //   body: {
  //     experiment_name: experiment,
  //     proxy_user_uid: proxyUID || fetchProxyUserUID()
  //   },
  //   headers: { ...(token ? { authorization: `Bearer ${token}` } : {}) },
  //   host: HOST_TYPE.AB_TESTING,
  //   useJWTToken: true,
  //   useCamelCase: false
  // }).then((res) => {
  //   experimentsStore
  //     .getState()
  //     .setExperimentVariations(
  //       experiment,
  //       res?.variation || abVariations.CONTROL
  //     );
  //   return res;
  // });
  // return data || {};
};
