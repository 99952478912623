import cookies from '@utils/cookies';
import { captureException } from '@utils/sentry';
import { GTMError } from '@utils/customErrors';
import getPlatform from '@utils/getPlatform';
import segmentStorage from '@events/segmentStorage';
import uuid from '@utils/uuid';
import { evtNameCategoryMap } from '@constants/index';
import useSegmentStatus from '@stores/useSegmentStatus';
import validateAndSetSessionId, {
  DEVICE_ID_COOKIE_NAME,
  SESSION_ID_COOKIE_NAME
} from '@utils/session/validateAndSetSessionId';

import * as amplitude from '@amplitude/analytics-browser';

// anonymous token
const anonymousToken = process.env.ANONYMOUS_EVENTS_TOKEN;

const eventsUrl = process.env.EVENTS_URL;

const getAnalyticsIntegrations = () => ({
  'Google Analytics': {
    clientId: window?.gaGlobal?.vid
  }
});

export const analyticsApi = async (dataLoad, eventType = null) => {
  const data = dataLoad;
  data['Anonymous Session Id'] = cookies.readCookie('anonymous_session_id');
  const token = cookies.readCookie('jwtToken') ?? anonymousToken;
  let payload = null;
  const eventURL =
    eventType === 'downloadPDF' ? process.env?.CLX_PERF_SERVER : eventsUrl;
  if (!eventURL || !token) return;
  if (eventType === 'downloadPDF') {
    const playerId = uuid(); // format 60666f78_b805_4d34_a0aa_650f9c5b80a8
    const browserId = uuid(); // same format as above different value

    payload = [
      {
        name: 'Liveclass Events',
        version: 1,
        data: {
          ...segmentStorage.getItem('systemInfo'),
          client_timestamp: new Date().getTime(),
          ...data,
          Platform: window.isMobile ? 7 : 0,
          'App Version': Number(process.env.APP_VERSION),
          user_id: `${data?.me?.userID}`,
          class_id: '',
          is_educator: false,
          player_version: 0,
          device_type: 'Web',
          class_type: 'App',
          os_platform: '',
          device: '',
          player_id: playerId,
          browser_id: browserId,
          evt_version: '4.3.0',
          application: 'LiveClass',
          ip: '',
          card: 1,
          measure1: 0,
          measure2: 0,
          measure3: 0,
          bandwidth: -1,
          network_type: 'na',
          network_rtt: -1,
          used_js_heap: -1,
          allocated_js_heap: -1,
          available_js_heap: -1,
          logical_core_count: -1,
          battery_charging: false,
          battery_time_to_charge: -1,
          battery_time_to_discharge: -1
        }
      }
    ];
  } else {
    payload = [
      {
        name: 'Android Events',
        version: 1,
        data: {
          ...segmentStorage.getItem('systemInfo'),
          client_timestamp: new Date().getTime(),
          ...data,
          Platform: window.isMobile ? 7 : 0,
          'App Version': Number(process.env.APP_VERSION)
        }
      }
    ];
  }
  try {
    if (eventType === 'downloadPDF' && !eventURL)
      throw new Error(
        `CLX url is not set issue. no name ${
          window.location.hostname
        } ${JSON.stringify(payload)}`
      );

    if (data.name)
      return await fetch(eventURL, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json;charset=UTF-8',
          'X-Platfrom': window.isMobile ? 7 : 0
        }
      });
    throw new Error(
      `Analytics issue. no name ${window.location.hostname} ${JSON.stringify(
        payload
      )}`
    );
  } catch (error) {
    captureException(error, {}, 400);
  }
};

export const callAnalytics = (dataLoad = {}) => {
  const data = dataLoad;
  if (window.isMobile) {
    data.Platform = getPlatform();
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push?.({
      event: data.name,
      ...data
    });
  } catch (e) {
    const error = new GTMError(
      `${e.message}; GTM dataLayer error.Event data:- ${JSON.stringify(data)}`
    );
    captureException(error);
  }

  analyticsApi(data);
};

let segmentLoaderInvoked = false;
const whiteListedEvents = [
  'Experiment - User Bucketing',
  'Notes - Page Navigation Clicked',
  'Planner - Calendar Date Selected',
  'Experiment - OTP Screen Viewed',
  'Enrollments - Section Viewed',
  'Planner - Continue Nudge Clicked',
  'Course Updates File Download'
];
const sendSegmentEvent = (eventName, payload) => {
  const segmentStatus = useSegmentStatus.getState().segmentLoaded;
  // eslint-disable-next-line prefer-const
  let subscriptionHandler;
  if (whiteListedEvents.includes(eventName)) {
    return;
  }
  const onSegmentFirstLoad = () => {
    if (segmentLoaderInvoked) return;
    segmentLoaderInvoked = true;
    const previousEvents = segmentStorage.getItem('previousEvents', []);
    segmentStorage.deleteItem('previous');
    subscriptionHandler();
    previousEvents.forEach(
      ({ eventName: previousEventName, payload: previousPayload }) => {
        window?.analytics?.track(previousEventName, previousPayload, {
          integrations: getAnalyticsIntegrations()
        });
      }
    );
  };
  subscriptionHandler = useSegmentStatus.subscribe(
    onSegmentFirstLoad,
    (state) => state.segmentLoaded
  );
  if (!segmentStatus) {
    useSegmentStatus.getState().setLoadSegment(true);
    const previousEvents = segmentStorage.getItem('previousEvents', []);
    segmentStorage.setItem('previousEvents', [
      ...previousEvents,
      { eventName, payload }
    ]);
  } else {
    window?.analytics?.track(eventName, payload, {
      integrations: getAnalyticsIntegrations()
    });
  }
  window?.dataLayer?.push?.({
    ...payload,
    event: eventName
  });
  if (window.FS && process.env.FULLSTORY_ID)
    window.FS?.event?.(eventName, {
      ...payload,
      event: eventName
    });
};

/*
 func -> validationForSegmentEvents
 args -> payload ->    object containing data to be sent
                      to event server ,
         eventsKey ->  arrays of predefined keys to be tested
                      against the payload

*/
const validationForSegmentEvents = (payload, eventsKey) => {
  const payloadKeys = Object.keys(payload);
  const keysNotPresentInPayLoad = [];
  eventsKey.forEach((eventKey) => {
    const keyPresent = payloadKeys.find(
      (payLoadKey) => payLoadKey === eventKey
    );
    if (!keyPresent) {
      keysNotPresentInPayLoad.push({ [eventKey]: 'Key not present' });
    }
    if (
      keyPresent &&
      typeof payload[keyPresent] !== 'boolean' &&
      !payload[keyPresent]
    ) {
      keysNotPresentInPayLoad.push({
        [eventKey]: 'Key present, but data falsy except for boolean value '
      });
    }
  });
};

const trackWithAPI = (eventName, payload = {}, deviceID) => {
  amplitude.track(eventName, payload);
  if (!process.env.SEGMENT_API_WRITE_KEY) return;
  const segmentData = segmentStorage.getItem('segmentData', {});
  const context = {
    page: {
      referrer: document.referrer,
      search: window.location.search,
      title: document.title,
      url: window.location.href
    },
    userAgent: navigator.userAgent,
    locale: navigator.language || navigator.userLanguage
  };
  if (payload.path) context.page.path = payload.path;
  const userId = segmentData.user_id || segmentData.learner_uid;
  const body = {
    userId,
    anonymousId: deviceID,
    learner_uid: segmentData.learner_uid,
    event: eventName,
    properties: { ...payload, learner_uid: segmentData.learner_uid },
    integrations: getAnalyticsIntegrations(),
    context
  };

  if (payload?.messageId) {
    body.messageId = payload.messageId;
  }

  fetch('https://api.segment.io/v1/track', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Basic ${btoa(`${process.env.SEGMENT_API_WRITE_KEY}:`)}`
    },
    body: JSON.stringify(body)
  });
  if (!window.dataLayer) window.dataLayer = [];
  const analyticsPayload = {
    ...payload,
    event: eventName
  };
  window.dataLayer.push?.(analyticsPayload);
  if (window.FS && process.env.FULLSTORY_ID)
    window.FS?.event?.(eventName, analyticsPayload);
  if (typeof window.fbq !== 'undefined')
    window.fbq('track', eventName, analyticsPayload);
};

export const segment = {
  // TODO: Change the arguments to named arguments
  track(
    eventName,
    properties = {},
    categoryStat = evtNameCategoryMap.WITHOUT_CATEGORY,
    validationCheck = false,
    eventsKey = [],
    isEducator = false
  ) {
    // Ensuring session_id is not null at event release time.
    validateAndSetSessionId();

    const sessionID = cookies.readCookie(SESSION_ID_COOKIE_NAME);
    const deviceID = cookies.readCookie(DEVICE_ID_COOKIE_NAME);
    const segmentData = { ...segmentStorage.getItem('segmentData', {}) };

    if (isEducator) {
      segmentData.educator_id = segmentData.user_id;
      segmentData.educator_uid = segmentData.learner_uid;
      segmentData.educator_username = segmentData.learner_username;
    }
    const payload = {
      last_primary_source_section: 'NA',
      ...segmentData,
      ...segmentStorage.getItem('systemInfo', {}),
      session_id: sessionID,
      anonymous_user_id: deviceID,
      ...properties
    };
    let googleClientId = segmentData?.client_id;
    if (googleClientId) {
      googleClientId = googleClientId.split('.').splice(2, 3).join('.');
    }

    if (googleClientId) {
      const identify1 = new amplitude.Identify().set(
        'google_analytics.clientId',
        googleClientId
      );
      amplitude.identify(identify1);
    }

    // amplitude.Identify().set('google_analytics.clientId', '123');
    // amplitude.setUserProperties({
    //   'google_analytics.clientId': '123'
    // });

    amplitude.setUserId(payload.learner_uid);

    if (properties.use_segment_api)
      return trackWithAPI(eventName, payload, deviceID);

    if (validationCheck && process.env.ENVIRONMENT === 'gamma')
      validationForSegmentEvents(payload, eventsKey);
    if (
      categoryStat === evtNameCategoryMap.WITHOUT_CATEGORY ||
      categoryStat === evtNameCategoryMap.BOTH
    )
      amplitude.track(eventName, payload);
    sendSegmentEvent(eventName, payload);

    if (
      categoryStat === evtNameCategoryMap.WITH_CATEGORY ||
      categoryStat === evtNameCategoryMap.BOTH
    ) {
      const newEventName = `${eventName} ${payload.goal_uid} Marketing`;
      amplitude.track(newEventName, payload);
      sendSegmentEvent(newEventName, payload);
    }
  }
};
